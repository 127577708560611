.m_b37d9ac7 {
  width: calc(100% - var(--mantine-spacing-md) * 2);
  position: fixed;
  z-index: var(--notifications-z-index);
  max-width: var(--notifications-container-width);
}

  .m_b37d9ac7:where([data-position='top-center']) {
    top: var(--mantine-spacing-md);
    left: 50%;
    transform: translateX(-50%);
  }

  .m_b37d9ac7:where([data-position='top-left']) {
    top: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='top-right']) {
    top: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='bottom-center']) {
    bottom: var(--mantine-spacing-md);
    left: 50%;
    transform: translateX(-50%);
  }

  .m_b37d9ac7:where([data-position='bottom-left']) {
    bottom: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='bottom-right']) {
    bottom: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
  }

.m_5ed0edd0 + .m_5ed0edd0 {
    margin-top: var(--mantine-spacing-md);
  }

@font-face {
font-family: 'fontPoppins';
src: url(/_next/static/media/8bf16c1a215e96a8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'fontPoppins';
src: url(/_next/static/media/260fb33a6c71b280-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'fontPoppins';
src: url(/_next/static/media/829fdf6ded001a03-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'fontPoppins Fallback';src: local("Times New Roman");ascent-override: 84.87%;descent-override: 28.29%;line-gap-override: 8.08%;size-adjust: 123.71%
}.__className_9c4f2b {font-family: 'fontPoppins', 'fontPoppins Fallback', system-ui, arial
}

.styles_fullHeader__y9jh770 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: inherit;
}
.styles_headerLeftSectionBlock__y9jh771 {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 0;
  white-space: nowrap;
  flex: 1;
  justify-content: flex-start;
}
.styles_headerRightSectionBlock__y9jh772 {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 0;
  white-space: nowrap;
  flex: 1;
  justify-content: flex-end;
}
.styles_headerLogoBlock__y9jh773 {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  padding: 0;
  white-space: nowrap;
  flex: 2;
}
.styles_menuItems__y9jh774 {
  display: flex;
  align-items: center;
  gap: calc(2rem * var(--mantine-scale));
  text-decoration: none;
  white-space: nowrap;
  height: 100%;
}
.styles_burgerContent__y9jh775 {
  padding: 0;
}
.styles_burgerInner__y9jh776 {
  z-index: 100000;
}
.styles_drawerCloseBtn__y9jh777 {
  position: relative;
  left: 400px;
}
.styles_menuItems__y9jh774 a {
  padding: 0 calc(1rem * var(--mantine-scale));
  display: flex;
  gap: calc(0.625rem * var(--mantine-scale));
  align-items: center;
  height: 100%;
  text-decoration: none;
}
.styles_accountIcon__y9jh778 {
  margin-right: calc(0.625rem * var(--mantine-scale));
}
.styles_searchTextInput__y9jh77a {
  padding-bottom: calc(0.75rem * var(--mantine-scale));
}
.styles_headerElement__y9jh77b {
  cursor: pointer;
}
.styles_burger__y9jh77c {
  font-weight: bold;
}
.styles_closeButton__y9jh77d {
  font-size: 12px;
}
.styles_navbarItem__y9jh77e {
  cursor: pointer;
}
.styles_navbarItem__y9jh77e:hover {
  text-decoration: underline;
}
.styles_noUnderline__y9jh77f {
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .styles_menuItem__y9jh779 {
    font-size: 0.975rem;
  }
}
.styles_appShellContentDefault__elo16s0 {
  background-color: #e9ecef;
}
.styles_appShellContentBranded__elo16s1 {
  background-image: url(/imgs/layout/domeBGfaded.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
.styles_appShellContentWhite__elo16s2 {
  background-color: white;
  padding-bottom: 0px !important;
}
.styles_appShellContentTransparent__elo16s3 {
  background-color: transparent;
}
.styles_inViewTriggerDiv__1j25awt1 {
  height: 1px;
}
.uppy-Dashboard-Item-action--edit .uppy-c-icon {
  visibility: hidden;
}
.uppy-Dashboard-Item-action--edit::before {
  content: "Edit Image";
  font-size: 12px;
  color: black;
  margin-left: 50px;
}
@media (min-width: 600px) {
  .uppy-StatusBar-actions {
    position: relative;
    left: 490px;
  }
  .uppy-u-reset.uppy-Dashboard-close {
    position: relative;
    top: -3px;
    left: 620px;
    color: black;
  }
  .uppy-DashboardContent-addMore .uppy-c-icon {
    display: none;
  }
  .uppy-DashboardContent-addMore button {
    display: none;
  }
  .uppy-u-reset.uppy-c-btn.uppy-StatusBar-actionBtn.uppy-StatusBar-actionBtn--upload.uppy-c-btn-primary {
    position: relative;
    left: 481px;
  }
  .uppy-DashboardContent-back {
    position: relative;
    top: 445px;
    left: 20px;
    border: 1px solid #e0e0e0;
    padding-right: 22px;
    padding-left: 22px;
    padding-top: 11px;
    padding-bottom: 11px;
    font-weight: 600;
    background-color: #f1f1f1;
    color: black;
    border-radius: 4px;
  }
  .uppy-DashboardContent-addMoreCaption {
    position: relative;
    top: 445px;
    right: 375px;
    border: 1px solid #e0e0e0;
    padding-right: 22px;
    padding-left: 22px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    background-color: #f1f1f1;
    color: black;
    border-radius: 4px;
    visibility: hidden;
  }
  .uppy-DashboardContent-addMoreCaption::after {
    content: "Add More Images";
    visibility: visible;
    border: 1px solid #e0e0e0;
    padding-right: 22px;
    padding-left: 22px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
    position: relative;
    left: 77px;
  }
  .uppy-ImageCropper-controls {
    width: 483px;
    bottom: 10px;
    left: 380px;
  }
  .uppy-Dashboard-Item.is-resumable {
    margin-right: 50px;
  }
  .uppy-Dashboard-AddFiles {
    height: calc(100% - 65px) !important;
    margin: 20px !important;
    margin-top: 10px !important;
    border-color: black !important;
  }
  .uppy-u-reset.uppy-c-btn.uppy-c-btn-primary.uppy-Dashboard-FileCard-actionsBtn {
    z-index: 10000;
  }
  .uppy-Dashboard-Item-fileInfo {
    max-width: 320px;
  }
}
@media (max-width: 600px) {
  .uppy-Dashboard-inner {
    background-color: #f1f1f1;
  }
  .uppy-Dashboard-AddFiles-title {
    font-size: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .uppy-Dashboard-AddFiles-title::before {
    content: "Add Images From Device";
    font-size: 14px;
    color: black;
    margin-bottom: 16px;
    visibility: visible;
    font-weight: 700;
  }
  .uppy-Dashboard-AddFiles-title button {
    font-size: initial;
    border: 1px solid #c9c8c5;
    background-color: white;
    border-radius: 8px;
    padding: 10px 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    outline: none !important;
    outline-color: #f1f1f1 !important;
    color: black;
  }
  .uppy-Dashboard-Item-actionWrapper {
    width: 100px;
    justify-content: flex-end;
    gap: 25px;
  }
  .uppy-u-reset.uppy-Dashboard-Item-action.uppy-Dashboard-Item-action--remove {
    margin-left: 25px !important;
    margin-top: 10px !important;
  }
  .uppy-u-reset.uppy-c-btn.uppy-Dashboard-Item-action.uppy-Dashboard-Item-action--edit {
    margin-top: 10px !important;
  }
  .uppy-DashboardContent-addMore .uppy-c-icon {
    display: none;
  }
  .uppy-DashboardContent-addMore {
    font-size: 14px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;
    min-width: 90px;
  }
  .uppy-DashboardContent-addMore::before {
    content: "Add Images";
    font-size: 14px;
    color: black;
    display: inline-block;
    white-space: nowrap;
  }
  .uppy-DashboardContent-back {
    visibility: hidden;
  }
}
